.wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: x-large;
    font-family:
    'Trebuchet MS', 'Lucida Sans Unicode',
    'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.dragdiv {
    padding: 8rem;
    color: blueviolet;
    cursor: grabbing;
    font-size: medium;
    font-weight: 600;
    font-family:
        'Trebuchet MS', 'Lucida Sans Unicode',
        'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}