.icon {
    width: 5rem;
    height: 5rem;
    margin: 2.5rem 1rem;
}

.icon-text {
    position: relative;
    text-align: center;

    top: 4rem;
}

.icon-img {
    position: absolute;

    width: 5rem;
}

.desktop {
    background-color: hsl(180, 100%, 35%);
    position: relative;
    height: 95vh;
    margin-top: 0;
    padding-top: 0;

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}