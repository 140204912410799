.startmenu {
    position: fixed;

    width: 10vw;
    height: 3rem;

    bottom: 0;
    left: 0;
    background-color: hsl(150, 50%, 20%);

    color: white;
}