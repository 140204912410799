html {
    margin: 0;
    padding: 0;
}

* {
    font-size: 16px;
}

.btn {
    cursor: pointer;
}